import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import 'katex/dist/katex.css'
import { ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MathBlock',
  props: {
    tex: {}
  },
  setup(__props: any) {

const props = __props

const renderedStr = ref<string>()
watch(() => props, async() => {
    const katex = await import('katex')
    renderedStr.value = katex.default.renderToString(props.tex, {
        throwOnError: true,
        displayMode: true,
    })
}, {
    immediate: true,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "math-block",
    innerHTML: renderedStr.value
  }, null, 8 /* PROPS */, _hoisted_1))
}
}

})