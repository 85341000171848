import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "simple-table" }
const _hoisted_2 = { key: 0 }

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleTable',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props

const theadRow = computed(() => props.data.length === 0 ? [] : props.data[0])
const tbodyRows = computed(() => props.data.length <= 1 ? [] : props.data.toSpliced(1))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        (theadRow.value.length > 0)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(theadRow.value.entries(), ([colIdx, col]) => {
                return (_openBlock(), _createElementBlock("td", { key: colIdx }, _toDisplayString(col), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tbodyRows.value.entries(), ([rowIdx, row]) => {
          return (_openBlock(), _createElementBlock("tr", { key: rowIdx }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.entries(), ([colIdx, col]) => {
              return (_openBlock(), _createElementBlock("td", { key: colIdx }, _toDisplayString(col), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}
}

})